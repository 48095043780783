/* Default styles for desktop */
.rs-menu {
    display: block;
  }
  
  .rs-menu.open {
    display: block;
  }
  
  .rs-menu-toggle {
    display: none; /* Hide toggle button by default on desktop */
  }
  
  /* Styles for mobile */
  @media (max-width: 1024px){
    .logo-area Link img{
     margin-bottom: 20px;
    }

    .mobile{
        display: flex;
        position: absolute;
        top: -30px;
        right: -660px;
        width: 100%;

    }
    .menu-1{
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 400px;
    }
    .rs-menu-container {
        position: relative;
      }

    .rs-menu {
      display: none; /* Hide menu by default on mobile */
      position: absolute;
      top: 60px; /* Adjust the top position based on your design */
      left: 0;
      width: 100%;
      background-color: #fff; /* Adjust the background color */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
  
    .rs-menu.open {
      display: block; /* Display menu when open on mobile */
    }
  
    .rs-menu-toggle {
      display: block; /* Display toggle button on mobile */
      cursor: pointer;
      z-index: 1001;
    }
  
    .overlay {
        /* Styles for overlay when menu is open */
        display: none; /* Hide overlay by default */
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
        z-index: 999; /* Place the overlay below the menu and toggle */
      }
    
      .rs-menu.open + .overlay {
        display: block; /* Display overlay when menu is open */
      }

      .submenu {
        display: none; /* Hide submenu by default on mobile */
    }

    .menu-item-has-children.menu-open .submenu {
        display: block; 
        z-index: 1000;/* Display submenu when menu item is clicked */
        background-color: #1a1a1a;
        color: rgb(255, 255, 255);
        font-size: 1.2rem;
        font-weight: 500;
    }
    .menu-item-has-children.menu-open .submenu li {
        padding: 0.5rem 0;
        border-bottom: none;
        color: white;
        font-size: 1.2em;
    }
  }
  @media (max-width: 767px) {

    .logo-area Link img{
      margin-bottom: 20px;
    }
    .mobile{
        display: flex;
        position: absolute;
        top: -30px;
        right: -280px;
        width: 100%;
    }
    .rs-menu-container {
        position: relative;
      }

    .rs-menu {
      display: none; /* Hide menu by default on mobile */
      position: absolute;
      top: 60px; /* Adjust the top position based on your design */
      left: 0;
      width: 100%;
      /* background-color: #fff; */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
  
    .rs-menu.open {
      display: block; /* Display menu when open on mobile */
    }
  
    .rs-menu-toggle {
      display: block; /* Display toggle button on mobile */
      cursor: pointer;
      z-index: 1001;
    }
  
    .overlay {
        /* Styles for overlay when menu is open */
        display: none; /* Hide overlay by default */
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
        z-index: 999; /* Place the overlay below the menu and toggle */
      }
    
      .rs-menu.open + .overlay {
        display: block; /* Display overlay when menu is open */
      }
      .submenu {
        display: none; /* Hide submenu by default on mobile */
    }

    .menu-item-has-children.menu-open .submenu {
        display: block; /* Display submenu when menu item is clicked */
    }
  }
  