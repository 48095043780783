.card-schedule{
	position:relative;
	.side-label{
		height: 100%;
		width: 10px;
		display: block;
		position: absolute;
		border-radius:0.625rem 0 0 0.625rem;
		
	}
	.up-comming-schedule{
		display:flex;
		align-items:center;
		border-radius: 0 0.625rem 0.625rem 0;
		background: white;
		padding: 19px 30px;
		margin-left: 12px;
		margin-top: 20px;
		justify-content:space-between;
		flex-flow: wrap;
		h4{
			font-size:18px;
			font-weight:600;
			
		}
		img{
			height:24px;
			width:24px;
			border-radius: 8px;
		}
		p{
			margin:0;
		}
		i{
			transform: scale(1.5);
		}
		.date-box{
			height: 50px;
			width: 50px;
			background:#fff;
			border-radius: 5px;
			padding: 5px 5px;
			display: flex;
			margin-right:.75rem;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color:$headings-color;
			box-shadow: 0 0 10px 1px rgba(0,0,0,0.05);
			span{
				display:block;
				font-weight: 500;
				text-align:center;
				line-height: 1.2;
			}
		}
		&.style-1{
			padding: 7px 14px;
			background:#f5f5f5;
			flex-flow: wrap;
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			justify-content:unset;
			h4{
				font-size: 16px;
			}
			&:hover{
				background:var(--rgba-primary-1);
				@include transitionMedium;
				h4{
					color:var(--primary);
					@include transitionMedium;
				}
				.date-box{
					background:var(--primary);
					@include transitionMedium;
					span{
						color:$white;
					}
				}
			}
		}
		@include respond ('phone'){
			padding: 19px 10px;
		}
	}
}

/* Instructors Page */

.instructors-box{
	.card-header{
		position:absolute;
		top:0;
		right:0;
	}
	.instructors-media{
		h4{
			margin: 10px 0;
		}
		img{
			height:120px;
			width:120px;
			border-radius:$radius;
			@include respond ('phone'){
				height:80px;
				width:80px;
			}
		}
		.custome-badge{
			margin: 25px 0;
			.badge{
				color:black;
				background:rgba(219, 219, 219, 0.25);
				margin: 0 4px;
				margin-bottom:3px;
				@include custommq($max: 106.25rem , $min:100rem) {
					margin: 0 2px;
				}
				@include respond ('tab-land'){
					font-size:12px;
				}
				@include respond ('phone-land'){
					margin-bottom:10px;
				}
				&:hover{
					background:var(--rgba-primary-1);
				}
			}
			
		}
		.info-box{
			background: #FAFAFA;
			padding: 17px 20px;
			margin: 0 10px;
			border-radius:$radius;
			@include custommq($max: 106.25rem , $min:100rem) {
				padding: 17px 11px;
				margin: 0 5px;
			}
			@include respond ('tab-land'){
				padding: 11px 7px;
				margin: 0 6px;
			}
			@include respond ('phone-land'){
				padding: 11px 20px;
			}
			span{
				font-size:18px;
				font-weight:400;
				white-space:nowrap;
				@include custommq($max: 106.25rem , $min:100rem) {
					font-size:16px;
				}
				@include respond ('laptop'){
					font-size:14px;
				}
				@include respond ('tab-port'){
					font-size:12px;
				}
				
				img{
					width:24px;
					height:24px;
					@include respond ('tab-port'){
						width:16px;
						height:16px;
					}
				}
			}
			h4{
				font-size:24px;
				font-weight:700;
				margin:0;
				@include respond ('laptop'){
					font-size:18px;
				}
				@include respond ('tab-port'){
					font-size:16px;
				}
			}
			&.style-1{
				width:194px;
				padding: 11px 17px;
			}
			&:hover{
				@include transitionMedium;
				background:var(--primary);
				span,h4{
					color:$white;
					@include transitionMedium;
				}
			}
		}
	}
}
.achievements{
	margin-top: 42px;
	.achievements-content{
		display: flex;
		justify-content: space-between;
		span{
			height: 60px;
			width: 60px;
			text-align: center;
			background: #fafafa;
			display: block;
			line-height: 60px;
			margin: 0 3px;
			border-radius:$radius;
			margin-bottom: 10px;
			@include respond ('phone'){
				height: 40px;
				width: 40px;
				line-height: 40px;
				img{
					width:22px;
				}
			}
			img{
				width:30px;
			}
			&:hover{
				img{
					-webkit-animation-name: rotateIn;
					opacity:1;
					animation-duration: 1.5s;
				}
			}
			
			
			
		}
	}
}
.bio{
	.bio-content{
		padding: 25px;
		background: #fafafa;
		border-radius:$radius;

	}
}
.donut-chart-sale small, .donut-chart-sale .small{
	font-size: 18px;
    position: absolute;
    width: 100%;
    height: 100%;
	font-weight:700;
    left: 0;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    font-weight: bold;
    color: #ff6175;
}

.app-fullcalendar1{
	height: 488px;
	.fc-toolbar{
		margin-bottom:1.3125rem!important
	}
}
.upcomming-task-widget{
	border-radius:$radius;
	background:#f9f9f9;
	padding: 12px 12px;
	margin: 18px 0;
	flex-flow: wrap;
	img{
		height:40px;
		width:40px;
		border-radius:$radius;
		margin-right:10px;
		
	}
	span{
		display:block;
	}
	h5{
		font-size:14px;
		margin:0;
		
	}
	&:hover{
		background:var(--rgba-primary-1);
		@include transitionMedium;
		h5{
			color:var(--primary);
		}
	}
}


//custom
.app-fullcalendar1{
	.fc .fc-view-harness{
		height: 100% !important;
		min-height: 476px !important;
	}
}
.process-calendar{
	.react-calendar{
		.react-calendar__navigation{
			margin-bottom: 0.5rem;
		}
		.react-calendar__tile{
			padding: 9px;
			&.react-calendar__month-view__days__day{
				height: unset;
			}
			&.react-calendar__tile--now,
			&.react-calendar__tile--active,
			&.react-calendar__tile--range{
				background: var(--secondary)!important;
				&:before{
					background: #000 !important;
				}
			}
		}
	}
}
