.bg-secondary{
	.student-box{
		h4{
			color:$white;
		}
	}
}
.student-box{
	.student-icon{
		height: 4.5rem;
		width: 4.5rem;
		border-radius: 0.625rem;
		line-height: 4.5rem;
		background: #fff;
		display: block;
		text-align: center;
		margin-bottom:1.25rem;
	}
	h4{
		font-size:1.5rem;
		margin:0;
		
	}
	span{
		font-size:1.125rem;
		color:$white;
	}
}
.students-list{
	.search-area{
		.form-control{
			background:#FAFAFA;
			border-left:0;
			&:focus{
				border-color:$border;
			}
		}
		.input-group-text{
			background:#FAFAFA;
		}
	}
	table{
		thead{
			tr{
				
				th{
					&:first-child{
						padding-left:0;
					}
					font-weight:500;
					font-size:1rem;
					
				}
				
			}
		}
		tbody{
			tr{
				td{
					white-space:nowrap;
					img{
						height:3.5rem;
						width:3.5rem;
						border-radius:$radius;
						margin-right:0.625rem;
					}
					&:first-child{
						padding-left:0;
					}
					&:last-child{
						text-align:end;
					}
				}
				&:last-child{
					td{
						padding-bottom:0;
					}
				}
			}	
		}
	}
}