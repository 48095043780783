@import "../../abstracts/variable";

/* input[type="checkbox"] {
    &:after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-top: 0px;
        margin-left: -1px;
        border: 1px solid transparent;
        border-radius: 3px;
        background: #d4d7da;
        line-height: 1.3;
    }

    &:checked:after{
        width: 1rem;
        height: 1rem;
        display: block;
        content: "\f00c";
        font-family: 'FontAwesome';
        color: $white;
        font-weight: 100;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        background: $primary;
    }

} */
.form-check-label{
    margin-left: 0.3125rem;
    margin-top: 0.1875rem;
}


.form-check-inline {
    .form-check-input {
        margin-right: 0.625rem;
    }
}
.form-check-input{
	top:0.125rem;
	border-width:0.125rem;
	width: 1.25rem;
	height: 1.25rem;
	border-color: #e7e7e7;
}
.rtl .form-check-label{
	&:before,
	&:after{
		right: -1.5rem !important;
		left: inherit;
	}
}
.form-check {
    line-height: normal;
}


// Toggle Switch
.toggle-switch{
	padding-left:3.125rem;
	line-height: 1.25;
	display:inline-block;
	color:$black;
	font-weight: 600;
	.form-check-input{
		border: 0;
		cursor:pointer;
		background: #d8d8d8;
		width: 2.3125rem;
		border-radius: 1.25rem !important;
		height: 0.875rem;
		position:relative;
		left: -0.3125rem;
		@include transitionFast;
		background-image:none;
		&:focus{
			background-image:none!important;
		}
		&:checked{
			background:var(--rgba-primary-2);
			background-image:none!important;
			&:after{
				left:1.25rem;
				background:var(--primary);
			}
		}
		&:focus{
			box-shadow:none;
		}
		&:after{
			width: 1.25rem;
			background:#909090;
			height: 1.25rem;
			content:"";
			position:absolute;
			border-radius: 1.5rem;
			top: -0.1875rem;
			left: 0;
			box-shadow:0 0 0.3125rem rgba(0,0,0,0.3);
			@include transitionFast;
		}
	}
	&.text-end{
		padding-right:0rem;
		padding-left:0;
		.form-check-input{
			left: auto;
			margin-left: 0;
			float:right;
			right: 0rem;
		}
		.form-check-label{
			margin-right: 0.9375rem;
			margin-left:0;
		}
	}
	.form-check-label{
		cursor:pointer;
	}
}


// CheckBox
.form-check-input:focus ~ .form-check-label::before{
	box-shadow:none !important;
}
.form-check-label::before{
    background-color: transparent;
    border-color: $light;
	border-width: 0.125rem;
	border-radius: 0.125rem !important;
		
	@at-root [data-theme-version="dark"] & {
		background-color: transparent;
		border-color: $d-border;
	}
}
.check-xs{
	.form-check-input{
		width: 1.125rem;
		height: 1.125rem;
	}
}
.check-lg{
	.form-check-input{
		width: 1.5rem;
		height: 1.5rem;
	}
}
.check-xl{
	.form-check-input{
		width: 1.75rem;
		height: 1.75rem;
	}
}
.checkbox{
	&-info{
		.form-check-input{
			&:focus{
				border-color: $info;
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgba($info , 0.25);
			}
			&:checked{
				background-color: $info;
				border-color: $info;
				@at-root [data-theme-version="dark"] & {
					background-color: $info-opacity;
					border-color: transparent;
				}
			}
		}
	}
	&-danger {
		.form-check-input{
			&:focus{
				border-color: $danger;
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgba($danger , 0.25);
			}
			&:checked{
				background-color: $danger;
				border-color: $danger;
				@at-root [data-theme-version="dark"] & {
					background-color: $danger-opacity;
					border-color: transparent;
				}
			}
		}
	}
	&-success{
		.form-check-input{
			&:focus{
				border-color: $success;
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgba($success , 0.25);
			}
			&:checked{
				background-color: $success;
				border-color: $success;
				@at-root [data-theme-version="dark"] & {
					background-color: $success-opacity;
					border-color: transparent;
				}
			}
		}
	}
	&-warning{
		.form-check-input{
			&:focus{
				border-color: $warning;
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgba($warning , 0.25);
			}
			&:checked{
				background-color: $warning;
				border-color: $warning;
				@at-root [data-theme-version="dark"] & {
					background-color: $warning-opacity;
					border-color: transparent;
				}
			}
		}
	}
	&-secondary{
		.form-check-input{
			&:focus{
				border-color: $secondary;
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgba($secondary , 0.25);
			}
			&:checked{
				background-color: $secondary;
				border-color: $secondary;
				@at-root [data-theme-version="dark"] & {
					background-color: $secondary-opacity;
					border-color: transparent;
				}
			}
		}
	}
}




//Check Switch
.check-switch{
	padding-left: 2.5rem;
	.form-check-label{
		line-height: 1.875rem;
		font-weight: 500;
		span{
			line-height: 1;
		}
		&:after,&:before{
			height:1.5rem;
			width:1.5rem;
			left: -2rem;
			border-radius: 3rem!important;
			border-color:var(--rgba-primary-3);
		}
	}
	/* .form-check-input:checked ~ .form-check-label::after{
		background-image:url('../images/svg/check.svg');
	} */
	.form-check-input:checked ~ .form-check-label::before{
		background:$white;
	}
}
















