
  .search-bar-container {
    position: relative;
    /* width: 100vw; */
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    font-family: "Nunito", sans-serif;
  }
  
  .search-results-container {
    position: absolute;
    top: 100%;
    left: 100;
    width: 345px;
    height: 300px;
    background-color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
    overflow-y: auto;
    /* border: 1px solid #ccc; */
    padding: 10px;
    margin-top: 3px;
    z-index: 1000;
  }
  .dataResult{
    position: absolute;
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1000;
}
  .searchInputs{
    margin-top: 10px;
    display: flex; 
    align-items: center; 
    /* justify-content: center;  */
    /* flex-direction: row; */
 }
 .search-bar-container input{
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 25px;
    height: 30px;
    /* max-width: 360px; */
}
@media (max-width: 991.98px) {
    .search-bar-container {
      margin-left: auto;
    }
  }
  @media (max-width: 767.98px) {
    .search-bar-container {
      max-width: 100%;
      width: 100%;
    }
  }
  .search-bar-container form {
    position: relative;
  }

.searchIcon{
    height: 30px;
    /* width: 50px; */
    background-color: #fff;
    display: flex;
    place-items: center;
    padding: 25px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.search-result-item{
    width: 100%;
    font-size: 18px;
    font-family: "Nunito", sans-serif;
    font-weight: 800;
    color: rgb(8, 8, 8);
    cursor: pointer;
}
.search-result-item:hover{
    background-color: #f1efef;
}

.searchIcon svg{
    font-size: 35px;
}

.dataResult::-webkit-scrollbar{
    display: none;
}
#clearBtn{
    cursor: pointer;
}

.tg-header__area .sticky{
    position: sticky;
    top: 0;
}

/* pagination button */
.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
 
}
.pagination button{
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: 0.3s;
}
.pagination button:hover{
    background-color: #ff2f2f;
    padding: 10px 15px;
    color: #fff;
}
.pagination button:active{
    background-color: #ff2f2f;
    padding: 10px 15px;
    color: #fff;
}
.pagination span{
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    color: #ff0000;
}

/*this is mobile version*/
/* search-mobile.css */


/* MobileMenu.css */
/* MobileMenu.css */
/* MobileMenu.css */

.mobile-menu-container {
  position: relative;
  display: flex;
  align-items: center;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.bar.open {
  transform: rotate(-45deg) translate(-4px, 4px);
  background-color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: block;
  z-index: 999;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: #ffffff;
  transition: right 0.4s;
  overflow-y: auto;
  z-index: 999;
}

.mobile-menu.open {
  right: 0;
}

.mobile-menu nav {
  padding: 20px;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

.mobile-menu li {
  margin-bottom: 10px;
}
.sub-menu-1 {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}
.menu-item-has-children.open .sub-menu-1 {
  max-height: 500px; 
  padding-left: 20px;
}

.menu-item-has-children Link {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  text-align: left;
}

.menu-item-has-children button:focus {
  background-color: transparent;
}

.menu-item-has-children.open .sub-menu-1 {
  display: block;
} 

.menu-item-has-children.open button::after {
  content: '-';
  margin-left: auto;
} 


.sub-menu-1 li {
  margin-bottom: 10px;
}

.sub-menu-1 li Link {
  color: #333;
  text-decoration: none;
}

