
   @media (max-width: 992px) {  
    .navbar-collapse {
      flex-direction: column;
    }
    .navbar-collapse a {
      margin-bottom: 15px;
    }
}

@media (min-width: 993px) {
    .navbar-collapse {
      flex-direction: row;
      flex-wrap: wrap;  
    }
    .navbar-collapse a {
      margin-right: 15px;
    }
}


/* mobile menu */


.mobile-menu-container {
  position: relative;
  display: flex;
  align-items: center;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.bar.open {
  transform: rotate(-45deg) translate(-4px, 4px);
  background-color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 999;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: #ffffff;
  transition: right 0.4s;
  overflow-y: auto;
  z-index: 999;
}

.mobile-menu.open {
  right: 0;
}

.mobile-menu nav {
  padding: 20px;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

.mobile-menu li {
  margin-bottom: 10px;
}
.sub-menu-1 {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}
.menu-item-has-children.open .sub-menu-1 {
  max-height: 500px; 
  padding-left: 20px;
}

.menu-item-has-children Link {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
  text-align: left;
}

.menu-item-has-children button:focus {
  background-color: transparent;
}

.menu-item-has-children.open .sub-menu-1 {
  display: block;
} 

.menu-item-has-children.open button::after {
  content: '-';
  margin-left: auto;
} 

.sub-menu-1 li {
  margin-bottom: 10px;
}

.sub-menu-1 li Link {
  color: #333;
  text-decoration: none;
}

